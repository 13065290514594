<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.local_estocagem.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :sem-botao-ok="!buscarPermissao('Acreditacao', 'Inserir')"
      :sem-filtro="!buscarPermissao('Acreditacao', 'Listar')"
      :titulo-botao-novo="$t('modulos.local_estocagem.titulos.novo')"
      @abrirNovo="abrirNovo"
    />
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('LocalEstocagem', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :mostrar-checkbox="buscarPermissao('LocalEstocagem', 'Deletar')"
      :colunas="tabela.colunas"
      :por-pagina="-1"
      sem-paginacao
    >
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('LocalEstocagem', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import LocalEstocagemService from '@common/services/cadastros/LocalEstocagemService.js';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nome',
            text: this.$t('modulos.local_estocagem.tabela.nome'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.local_estocagem.tabela.descricao'),
            sortable: false,
          },
        ],
        quantidadeItens: 0,
      },
    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'LocalEstocagem', 'Listar');
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.local_estocagem.titulos.listagem')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    listarRegistros: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      LocalEstocagemService.listar()
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        let promises = [];
        this.tabela.selecionados.forEach((registro) => {
          promises.push(LocalEstocagemService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(
              this.$t(`modulos.local_estocagem.exclusao_sucesso`)
            );
            this.tabela.selecionados = [];
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'local-estocagem-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'local-estocagem-editar',
        params: { id: item.id },
      });
    },
  },
};
</script>
